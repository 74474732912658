<template>
    <section class="section">
        <div class="container">
            <div class="event">
                <Placeholder v-if="isLoading" />
                <div v-else>
                    <div class="card">
                        <div class="card__body">
                            <div class="row">
                                <div class="col-md-5">
                                    <VLazyImage
                                        :src="event.image"
                                        :src-placeholder="placeholder"
                                        class="event-image"
                                    />
                                </div>
                                <div class="col-md-7">

                                    <div class="event-info">
                                        <h4>{{ event.title }}</h4>

                                        <small>Date</small>
                                        <p>{{ event.expiry }}</p>

                                        <small>Description</small>
                                        <p class="description">{{ event.description }}</p>

                                        <small>Organizer</small>
                                        <p>{{ event.organizer.name }}</p>
                                    </div>

                                    <!-- <div class="event-details">
                                        <form @submit.prevent="onDonate">
                                            <div class="form-group">
                                                <label for="amount">Enter your donation</label>
                                                <div class="donate-box">
                                                    <div class="currency">
                                                        <div class="symbol">¢</div>
                                                        <div class="abbr">GHS</div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        class="amount"
                                                        id="amount"
                                                        readonly
                                                        onkeypress="return !(event.charCode == 46)"
                                                        v-model="checkout.value"
                                                        @blur="onAmountBlurred"
                                                    />
                                                    <div class="digit">.00</div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="check-box">
                                                    <input type="checkbox" id="isAnonymous" v-model="checkout.isAnonymous" @change="onAnonymousChanged" />
                                                    <label for="isAnonymous">Anonymous</label>
                                                </div>
                                            </div>

                                            <div v-if="!checkout.isAnonymous" class="m-b-30">
                                                
                                                <div class="form-group">
                                                    <label for="">Email <em>(required)</em></label>
                                                    <input
                                                        type="email"
                                                        class="form-control"
                                                        v-model="checkout.email"
                                                        required
                                                    />
                                                </div>

                                                <div class="form-group">
                                                    <label for="">Name</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="checkout.name"
                                                    />
                                                </div>
                                                
                                            </div>

                                            <div class="form-group">
                                                <button class="btn btn-success btn-block" type="submit" :disabled="isDonating" >
                                                    Give Now
                                                    <Spinner v-if="isDonating" />
                                                </button>
                                            </div>
                                        
                                        </form>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import { doc, getDoc, getFirestore, setDoc } from "@firebase/firestore";
import dayjs from "dayjs";
import PayStack from 'paystack';
const db = getFirestore();
const Pay = new PayStack('sk_live_a53679564e88325e9e54fcf766ebb75a7bdab13f');
import { v4 as uuidv4 } from 'uuid';
export default {
    data() {
        return {
            isLoading: false,
            isLoadingContestants: false,
            isDonating: false,
            event: {},
            categories: [],
            nominees: [],
            isFixed: false,
            placeholder: "/img/placeholder.jpg",
            loadContestantsByCategory: [],
            checkout: {
                value: null,
                amount: 0,
                email: '',
                name: '',
                telephone: '',
                isAnonymous: false,
                type: 'FUNDRAISER',
                votes: '',
            }
        };
    },
    created() {
        this.getEvent();
    },
    methods: {

        async getEvent() {
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events/${eventId}`);
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            data.expiry = dayjs(data.date.toDate()).format("DD MMMM, YYYY");
            data.company = data.organizer.name;
            this.event = data;
            this.isLoading = false;
        },

        onAnonymousChanged($event){
            const { target } = $event;
            const { checked } = target;
            if (checked) {
                this.checkout.email = 'anonymous@smartkastghana.com';
                this.checkout.name = 'Anonymous';
            } else {
                this.checkout.email = ''
                this.checkout.name = ''
            }
        },

        onAmountBlurred(){
            const value = this.checkout.value
            if (value != null) {
                const formattedAmount = value * 100;
                this.checkout.amount = Number(formattedAmount);
            }
        },

        async onDonate() {
            
            this.isDonating = true;

            const event_id = this.$route.params.eventId;
            
            const reference = uuidv4();
            
            let { email, amount, value, isAnonymous, name, telephone } = this.checkout;
            
            if (value != null) {
                const formattedAmount = value * 100;
                amount = Number(formattedAmount);
            }

            const { paystack, title: event_title, date: event_date } = this.event;

            const { percentage_charge } = paystack;

            const transaction_payload = {
                reference,
                email,
                amount: Number(value), 
                event_id: this.$route.params.eventId,
                event_title,
                event_date,
                isAnonymous,
                name,
                telephone,
                type: 'FUNDRAISER',
                status: 'PENDING',
                created_at: dayjs().toDate(),
                updated_at: dayjs().toDate(),
                is_completed: false,
                channel: "WEB",
                event_type: "FUNDRAISER",
            }

            const ref = doc(db, "transactions", reference);
            
            await setDoc(ref, transaction_payload);

            const charge = Number(percentage_charge);

            const metadata = {
                event_id,
                reference,
                event_type: 'FUNDRAISER',
                percentage_charge: charge,
            };

            const payload = { 
                email,
                first_name: name,
                amount,
                reference,
                metadata
            };

            const body = await Pay.transaction.initialize(payload);

            if (body.status) {
                window.location = body.data.authorization_url;
            }

        },
    },
};
</script>